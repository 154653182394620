
import 'skulpt-pygame-zero'
const Sk = window.Sk;
const PyGameZero = window.PyGameZero;
const stageSize = {width: 0, height: 0};
export const init = (stage, outFn) => {
  var externalLibs = {
  };
  function builtinRead(file) {
    console.log("Attempting file: " + Sk.ffi.remapToJs(file));

    // 加载模块
    if (PyGameZero.matchModelName(file)) {
      return PyGameZero.load(file)
    }
    
    if (externalLibs[file] !== undefined) {
      return Sk.misceval.promiseToSuspension(
        fetch(externalLibs[file]).then(
          function (resp){ return resp.text(); }
        ));
    }
    
    if (Sk.builtinFiles === undefined || Sk.builtinFiles.files[file] === undefined) {
      throw "File not found: '" + file + "'";
    }
    
    return Sk.builtinFiles.files[file];
  }

  const stageEl = document.getElementById(stage);

  // 配置容器
  stageSize.width = 500;
  stageSize.height = 375;
  PyGameZero.setContainer(stageEl)

  if (!Sk.TurtleGraphics) {
    Sk.TurtleGraphics = {}
  }
  Sk.TurtleGraphics.target = stage;
  Sk.TurtleGraphics.width = 500
  Sk.TurtleGraphics.height = 375

  Sk.pre = "output";
  Sk.configure({
    output: outFn,
    read: builtinRead,
    __future__: Sk.python3,
  });
}

export const run = function(value) {
  Sk.TurtleGraphics.reset && Sk.TurtleGraphics.reset()
  return new Promise((resolve, reject) => {
    var prog = value;
    const stage = document.getElementById('stage');
    stage.style.width = ''
    stage.style.height = ''
    stage.innerHTML = ''
    const size = {
      width: stage.offsetWidth,
      height: stage.offsetHeight,
    }
    stage.style.width = `500px`
    stage.style.height = '375px'
    PyGameZero.reset();
    var myPromise = Sk.misceval.asyncToPromise(function() {
      return Sk.importMainWithBody("<stdin>", false, prog, true);
    });
    myPromise.then(
      function() {
        resize(size)
        stage.style.width = ''
        stage.style.height = ''
      },
      function(err) {
        reject(err)
        console.error(err);
      }
    );
  })
}

export const resize = (size) => {
  const app = PyGameZero.app;
  if (app) {
    app.stage.scale.x = size.width / stageSize.width
    app.stage.scale.y = size.height / stageSize.height
    app.view.width = size.width;
    app.view.height = size.height;
    app.renderer.resize(size.width, size.height);
  }
}