
// 获取本地文件夹内的文件列表
export const getFilesByDirectory = async () => {
  const directoryHandle = await window.showDirectoryPicker()
  const arr = [];
  for await (let [file, fileHandle] of directoryHandle) {
    arr.push({
      name: file,
      handle: fileHandle,
    })
  }
  return arr;
};

// 通过filehandle获取文件内容
export const getFileData = async (fileHandle) => {
  // const status = await fileHandle.queryPermission({ mode : "readwrite" })
  // const request = await fileHandle.requestPermission({ mode : "readwrite" })
  // console.log(status,request)
  const file = await fileHandle.getFile()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = function() {
      resolve(this.result)
    }
    reader.onerror = function () {
      reject()
    }
  })
};

// 打开本地文件
export const openFile = async () => {
  const [fileHandle] = await self.showOpenFilePicker({ multiple: false,  types: [
    {
      description: 'python file',
      accept: {
        'text/python': ['.py', '.python'],
      }
    },
  ], });
  const data = await getFileData(fileHandle)
  return {
    fileHandle: fileHandle,
    name: fileHandle.name,
    data: data,
  }
}

// 修改文件
export const writeFile = async (fileHandle, data) => {
  const stream = await fileHandle.createWritable();
  await stream.write({ type: "write", data: data})
  await stream.close();
}

// 选择文件并修改文件
export const writeWithFilePicker = async () => {
  const fileHandle = await self.showOpenFilePicker({ multiple: false });
  const stream = await fileHandle[0].createWritable();
  await stream.write({ type: "write", data: 'bbbbbb'})
  await stream.close();
}

// 获取文件在文件夹的相对路径
export const resolveRelativePath = async () => {
  const fileHandle = await self.showOpenFilePicker({ multiple: false });
  const directoryHandle = await window.showDirectoryPicker()
  const relative_path = await directoryHandle.resolve(fileHandle[0])
  return relative_path
}

// 删除文件
export const removeFileWithPicker = async (filename) => {
  const directoryHandle = await window.showDirectoryPicker()
  directoryHandle.removeEntry(filename, {recursive: true })
}


// 在文件夹创建文件
export const createFileOnDirectory = async (filename) => {
  const directoryHandle = await window.showDirectoryPicker()
  directoryHandle.getFileHandle(filename, { create: true })
}

// 在文件夹创建文件夹
export const createDirectorOnDirectory = async (directoryName) => {
  const directoryHandle = await window.showDirectoryPicker()
  directoryHandle.getDirectoryHandle(directoryName, { create: true })
}


// 创建文件选择存放地址并写入数据，这跟发起文件下载的区别在与这是在本地做的操作，而文件下载是从当前网站下载的
export const createFileWithSavePicker = async () => {
  const opts = {
    types: [{
      description: 'Text file',
      accept: {'text/plain': ['.txt']},
    }],
  };
  const fileHandle = await window.showSaveFilePicker(opts);
  const stream = await fileHandle.createWritable();
  await stream.write({ type: "write", data: 'bbbbbb'})
  await stream.close();
}