<template>
  <div class="editor-container">
    <div class="editor-instance" id="editor-instance"></div>
  </div>
</template>

<script>
import { listen } from 'vscode-ws-jsonrpc'
// import {
//   MonacoLanguageClient,
//   MonacoServices,
//   CloseAction,
//   ErrorAction,
//   createConnection
// } from 'monaco-languageclient'
// function createLanguageClient(connection) {
//   return new MonacoLanguageClient({
//     name: 'Monaco language client',
//     clientOptions: {
//       documentSelector: ['python'],
//       errorHandler: {
//         error: () => ErrorAction.Continue,
//         closed: () => CloseAction.DoNotRestart
//       }
//     },
//     connectionProvider: {
//       get: (errorHandler, closeHandler) => {
//         return Promise.resolve(createConnection(connection, errorHandler, closeHandler))
//       }
//     }
//   })
// }

// function connectToMonacoServer() {
//   // const webSocket = new WebSocket(`wss://python.codeasily.net/langserver/python`)
//   const webSocket = new WebSocket(`wss://${location.host}/langserver/python`)
//   listen({
//     webSocket: webSocket,
//     onConnection: (connection) => {
//       var languageClient = createLanguageClient(connection)
//       var disposable = languageClient.start()
//       connection.onClose(function() {
//         return disposable.dispose()
//       })
//       connection.onError(function(error) {
//         console.log(error)
//       })
//     }
//   })
// }
import * as monaco from "monaco-editor";
window.monaco = monaco;
export default {
  name: "Editor",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: ['value'],
  data() {
    this.editor = void 0;
    this.curMode = void 0;
    this.actions = [];
    this.isCompositionInput = false;
    this.curAction = 0;
    this.changes = void 0;
    this.changeCursor = void 0;
    this.autoSelection = false;
    this.editorModels = {}
    return {
      isRecording: false,
      isPlaying: false,
      framePosition: 0,
      maxFrames: 0,
      curProgress: 0,
      remainingTime: 0,
      canTransition: true,
    }
  },
  watch: {
    value(newVal) {
      if (this.curMode.getValue() !== newVal) {
        this.curMode && this.curMode.setValue(newVal)
      }
    }
  },
  mounted() {
    var editorConfig = {
      // value: this._state.curValue[this._state.curFile] || '',
      language: "python",
      theme: "vs",
      fontSize: 16,
      automaticLayout: true,
      lineNumbersMinChars: 3,
      minimap: {enabled: false}
    }
    monaco.languages.register({
      id: 'python',
      extensions: ['.py'],
      aliases: ['python'],
      mimetypes: ['application/text'],
      model: monaco.editor.createModel('', 'python', monaco.Uri.parse('file:///abs/path/to/demo/ts/file.ts')),
    })
    this.editor = monaco.editor.create(
      document.getElementById("editor-instance"),
      editorConfig
    );
    // console.log(this.editor)
    // MonacoServices.install(this.editor)
    // connectToMonacoServer()
    const identifierPattern = "([a-zA-Z_]\\w*)";	// 正则表达式定义 注意转义\\w
    function getTokens(code) {
        let identifier = new RegExp(identifierPattern, "g");	// 注意加入参数"g"表示多次查找
        let tokens = [];
        let array1;
        while ((array1 = identifier.exec(code)) !== null) {
            tokens.push(array1[0]);
        }
        return Array.from(new Set(tokens));			// 去重
    }
    
    // monaco.languages.registerCompletionItemProvider('python', {
    //       provideCompletionItems: (model, position, context, token) => {
    //         // console.log(model, position,context, token)

    //         var word = model.getWordUntilPosition(position);
    //         var textUntilPosition = model.getValueInRange({startLineNumber: 1, startColumn: 1, endLineNumber: position.lineNumber, endColumn: position.column});
    //         console.log(textUntilPosition)

    //         const match = textUntilPosition.match(/Actor\([^)]*\)/)

    //         let words = [];
    //         let tokens = getTokens(model.getValue());
    //         for (const item of tokens) {
    //             if (item != word) {
    //                 words.push({
    //                     label: item,
    //                     kind: monaco.languages.CompletionItemKind.Text,	// Text 没有特殊意义 这里表示基于文本&单词的补全
    //                     documentation: "",
    //                     insertText: item,
    //                     range: range
    //                 });
    //             }
    //         }
    //         // console.log(match)
    //         var range = {
    //             startLineNumber: position.lineNumber,
    //             endLineNumber: position.lineNumber,
    //             startColumn: word.startColumn,
    //             endColumn: word.endColumn
    //         };
    //         return {
    //             suggestions: [
    //               ...words,
    //               {
    //                   label: 'Actor',
    //                   kind: monaco.languages.CompletionItemKind.Function,
    //                   insertText: 'Actor',
    //                   commitCharacters: ['1','2'],
    //                   detail: '实例化一个角色',
    //                   documentation: '这是一个pgzrun的角色类',
    //                   range: range,
    //               }
    //             ]
    //         };
    //       },
    //       triggerCharacters: ['.']
    //   });
    document.getElementById('editor-instance').ontouchmove = function(e) {
      var touchs = e.touches || e.targetTouches
      if (touchs.length == 1) {
        e.preventDefault()
      }
    }
  },
  methods: {
    // 父组件拿到数据后初始化
    init() {
      const model = monaco.editor.createModel( 'test', 'python')
      this.editor.setModel(model);
      this.curMode = model
      this.curMode.onDidChangeContent(() => {
        this.$emit('change', this.curMode.getValue());
      })
      // this.editorModels[this._state.curFile] = model.uri
    },
    setValue(val) {
      this.curMode.setValue(val)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.editor-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.editor-instance {
  width: 100%;
  height: 100%;
}
</style>
