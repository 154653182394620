<template>
  <div class="page">
    <div class="menu-bar">
      <button class="select-samples">
        打开示例
        <div class="dropdown-box">
          <div class="sample-item" @click="loadFile('simple')">simple</div>
          <div class="sample-item" @click="loadFile('turtle')">turtle</div>
          <div class="sample-item" @click="loadFile('pygame-zero')">pygame-zero</div>
          <div class="sample-item" @click="loadFile('physics-engine')">physics-engine</div>
        </div>
      </button>
      <button class="select-samples" @click="openDirectory">
        打开本地文件
      </button>
    </div>
    <button class="btn-run" :style="{left: `${separateWidth.left - 80}px`}" @click="run">运行</button>
    <div class="area-left" :style="{width: `${separateWidth.left}px`}" :class="{'local-file': currentLocalFile.name}">
      <div class="file-bar" :class="{saved: isSaved}">{{currentLocalFile.name}}</div>
      <Editor v-model="value" ref="myCm"/>
      <div class="drag-line" ref="drag-line"></div>
    </div>
    <div class="area-right" :style="{width: `${separateWidth.right}px`}">
      <div class="area-right-top" :style="{height: `${stageHeight}px`}">
        <div class="stage" id="stage"></div>
      </div>
      <div class="area-right-bottom">
        <div class="output" id="output">
          <p class="output-tips">输出结果：</p>
          <div class="console-item" v-for="(item, index) in consoleList" :key="item + index">
            {{item}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import 'codemirror/mode/python/python'
// import VueCodemirror from 'vue-codemirror'
// import 'codemirror/lib/codemirror.css'
// import 'codemirror/theme/idea.css'
// import 'codemirror/addon/hint/show-hint'
// import 'codemirror/addon/hint/show-hint.css'
// import 'codemirror/addon/hint/anyword-hint'
import Editor  from './editor'
import {debounce} from '/src/utils/base'
import {init, run, resize} from './sk'
import { getFilesByDirectory, getFileData, createFileOnDirectory, writeWithFilePicker, openFile, writeFile } from '/src/utils/filesystem'

// const {codemirror, CodeMirror} = VueCodemirror

// console.log(CodeMirror)
export default {
  components: {
    Editor
  },
  data() {
    const windowW = window.innerWidth < 1000 ? 1000 : window.innerWidth;
    this.resizeStage = debounce(resize, 300)
    // const handleShowHint = (editor, options) => {
    //     const cur = editor.getCursor();
    //     const curLine = editor.getLine(cur.line);
    //     const end = cur.ch;
    //     const start = end;
    //     let list = [];
    //     // 根据不同情况给list赋值，默认为[]，即不显示提示框。
    //     const cursorTwoCharactersBefore = `${curLine.charAt(start - 2)}${curLine.charAt(start - 1)}`;
    //     // const {activeItem, openedItemsData} = this.props.configureStore;
    //     // const {variablePool} = openedItemsData[activeItem].config;
    //     // const variablePoolKeys = variablePool ? Object.keys(variablePool): [];
    //     console.log(cursorTwoCharactersBefore)
    //     if(cursorTwoCharactersBefore === '${'){
    //         list = ['fff', 'fewfw','fefw'];
    //     }
    //     // else if(cursorTwoCharactersBefore === '::'){
    //     //     const lastIndex = curLine.lastIndexOf('${', start);
    //     //     const modelId = curLine.substring(lastIndex + 2, start - 2);
    //     //     if(lastIndex !== -1 && modelId && variablePool[modelId]){
    //     //         list = variablePool[modelId].attributes.concat(variablePool[modelId].points);
    //     //     }else {
    //     //         list = [];
    //     //     }
    //     // }
    //     return {list: list, from: CodeMirror.Pos(cur.line, start), to: CodeMirror.Pos(cur.line, end)};
    // }
    
    return {
      options: {
        lineNumbers: true,
        mode:  "text/x-cython",
        theme: "idea",
        lineWrapping: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        indentWithTabs: true,
        smartIndent: true,
        autoCloseBrackets: true,
        hintOptions:{
          // hint: handleShowHint
        }
      },
      value: "",
      isSaved: true,
      currentLocalFile: {},
      consoleList: [],
      separateWidth: {
        left: windowW * .5,
        right: windowW * .5,
      },
    }
  },
  computed: {
    codemirror() {
      return this.$refs.myCm.codemirror
    },
    stageHeight() {
      return this.separateWidth.right * (3/4);
    }
  },
  mounted() {
    const editor = this.$refs.myCm
    editor.init()
    
    // editor.on('cursorActivity', function () {
    //   editor.showHint()
    //   // editor.execCommand("autocomplete");
    // })

    fetch('./test/pygame-zero.py').then((res) =>  res.text()).then((text) => {
      this.value = text;
      // editor.setValue(text)
      init('stage', (text) => {
        this.consoleList.push(text);
      })
    })

    this.$refs['drag-line'].addEventListener('mousedown', (e) => {
      e.preventDefault()
      const originX = e.clientX;
      const leftWidth = this.separateWidth.left;
      const rightWidth = this.separateWidth.right
      const moveListener = (e) => {
        const X = e.clientX;
        const deltaX = X - originX;
        this.separateWidth.left = leftWidth + deltaX
        this.separateWidth.right = rightWidth - deltaX
      }
      document.addEventListener('mousemove', moveListener)
      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove',moveListener)
      })
    })

    window.addEventListener('resize', () => {
      const windowW = window.innerWidth < 1000 ? 1000 : window.innerWidth;
      this.separateWidth = {
        left: windowW * .5,
        right: windowW * .5,
      }
    })
    window.addEventListener('keydown', (e) => {
      if (e.metaKey && e.key === 's' && this.currentLocalFile.fileHandle) {
        e.preventDefault();
        try {
          writeFile(this.currentLocalFile.fileHandle, this.value)
          this.isSaved = true;
        } catch(err) {
          return void 0;
        }
      }
    })
  },
  watch: {
    ['separateWidth.right'](newVal) {
      this.resizeStage({
        width: newVal, 
        height: newVal * (3/4),
      })
    },
    value(newVal, oldVal) {
      if (newVal!=oldVal) {
        if (this.currentLocalFile.name) {
          this.isSaved = false;
        }
      }
    }
  },
  methods: {
    loadFile(file) {
      fetch('./test/' + file + '.py').then((res) =>  res.text()).then((text) => {
        this.value = text;
        this.currentLocalFile = {};
      })
    },
    run() {
      this.consoleList = [];
      run(this.value).then(() => {
        console.log('执行成功')
      }).catch((err) => {
        this.consoleList.push(err);
      })
    },
    async openDirectory() {
      try {
        const result = await openFile()
        this.currentLocalFile = result;
        this.value = result.data;
        this.$nextTick(() => {
          this.isSaved = true;
        })
      } catch(err) {
        return void 0;
      }
    }
  }
}
</script>

<style lang="scss">
.vue-codemirror {
  height: 100%;
}
.CodeMirror {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 100%;
  font-size: 18px;
}
</style>

<style lang="scss" scoped>
@import './index';

</style>